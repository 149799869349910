<template>
    <div>
        <v-combobox v-model="modelValue" :label="label" multiple chips deletable-chips persistent-placeholder
            class="no-arrow" :rules="getEmailArrayRules(false)">
            <template v-slot:selection="{ item, index }">
                <v-chip close @click:close="removeEmail(index)" color="primary">
                    {{ item }}
                </v-chip>
            </template>
        </v-combobox>
    </div>
</template>

<script>
import { getEmailArrayRules } from '@/shared/utils/inputRulesUtils.js';

export default {
    emits: ['update:value'],
    props: {
        value: Array, // Parent can bind v-model to get emails
        label: {
            type: String,
            default: "Emailadres" // Default label if not provided
        }
    },
    methods: {
        getEmailArrayRules,
        removeEmail(index) {
            this.value.splice(index, 1);
            this.$emit("input", this.value); // Emit updated list after removal
        }
    },

    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(newValue) {
                this.$emit('input', newValue)
            }
        }
    },
};
</script>
<style scoped>
/* Hide the dropdown arrow */
.no-arrow .v-input__control .v-input__append-inner {
    display: none !important;
}

/* Prevent the dropdown menu from showing */
.v-menu__content {
    display: none !important;
}
</style>