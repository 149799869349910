<template>
    <InvoiceEditCard :confirmation-message="getConfirmationMessage" :title="getFormTitle"
        update-store-action="invoicesModule/sendInvoice" :update-store-action-payload="getInvoiceSendPayload"
        :invoiceId="invoiceId" :promises.sync="promises" :success-callback="invoiceSendSuccessCallback">
        <template v-slot:form-fields>
            <v-row>
                <v-col cols="12" md="6">
                    <MultiEmailInput v-model="recipients.to" label="Aan" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <MultiEmailInput v-model="recipients.cc" label="Cc" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <MultiEmailInput v-model="recipients.bcc" label="Bcc" />
                </v-col>
            </v-row>
        </template>
        <template v-slot:save-button-content>
            <slot name="save-button-content">
                Verzenden
            </slot>
        </template>
    </InvoiceEditCard>
</template>
<script>
import InvoiceEditCard from "@/views/invoice/InvoiceEditCard.vue";
import { getEmailRules } from '@/shared/utils/inputRulesUtils.js';
import { applyDateFormat } from "@/shared/utils/dateUtils";
import RouteNames from "@/router/RouteNames";
import MultiEmailInput from "@/components/shared/fields/MultiEmailInput.vue";

export default {
    name: "InvoiceSend",
    props: {
        invoiceId: {
            type: String,
            required: true,
        },
        credit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            RouteNames,
            promises: [
                this.$store.dispatch("invoicesModule/fetchInvoiceContactEmail", { invoiceId: this.invoiceId })
                    .then(result => {
                        if (result && result.trim().length > 0 ) {
                            this.recipients.to.push(result)
                        }
                    })
            ],
            recipients: {
                to: [],
                cc: [],
                bcc: []
            }
        }
    },
    methods: {
        getEmailRules,
        applyDateFormat,
        invoiceSendSuccessCallback() {
            this.$router.push({ name: RouteNames.INVOICE_DETAIL, params: { invoiceId: this.invoiceId } })
        },
    },
    components: {
        InvoiceEditCard,
        MultiEmailInput
    },
    computed: {
        getConfirmationMessage() {
            if (this.credit) {
                return "Creditnota werd verzonden"
            } else {
                return "Factuur werd verzonden"
            }
        },
        getFormTitle() {
            if (this.credit) {
                return "Verstuur creditnota"
            } else {
                return "Verstuur factuur"
            }
        },
        getInvoiceSendPayload() {
            return { invoiceId: this.invoiceId, recipients: this.recipients }
        },
    }
}
</script>